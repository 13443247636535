import $ from 'jquery'

import { showMiniCart, hideMiniCart, refreshMiniCart, refreshAndShowMiniCart } from './cart';
import './skin';
import './animations';
import "./forms";
import 'flickity';
import 'flickity-as-nav-for';
import 'intersection-observer';

import tileCarousels from './carousels/tileCarousels';

//import eac_css from 'easy-autocomplete/src/sass/easy-autocomplete.scss';
import EasyAutocomplete from "easy-autocomplete";

import { formDataToBody, makeFormData } from '../../../CRA/src/Components/util/catalystFormDataBuilder';

global.jQuery = $;
window.jQuery = $;
window.$ = $;


document.addEventListener('DOMContentLoaded', () => {
    tileCarousels();
});

global["MiniCart"] = global["MiniCart"] || {}
global.MiniCart.show = showMiniCart;
global.MiniCart.hide = hideMiniCart;
global.MiniCart.refresh = refreshMiniCart;
global.MiniCart.refreshAndShow = refreshAndShowMiniCart;

global.EasyAutocomplete = EasyAutocomplete;

global.FormDataUtil = {
    makeFormData,
    formDataToBody
}

import {removeTitleFromLicense} from "./cart";
global.removeTitleFromLicense = removeTitleFromLicense;

import { initContentHubJs } from "./pages/contenthub.js";
global.initContentHubJs = initContentHubJs;

import { initEventsJs } from "./pages/events.js";
global.initEventsJs = initEventsJs;

import { contentRecommendationsSetUpObserver } from "./util/contentRecommendations";
global.contentRecommendationsSetUpObserver = contentRecommendationsSetUpObserver;

import { attachPasswordVisibility, initializeTooltip } from "./forms";
global.attachPasswordVisibility = attachPasswordVisibility;
global.initializeTooltip = initializeTooltip;

import { formatDss, ellipsesAfter, encodeBase64, submitPostPurchaseForm } from "./util";
global.formatDss = formatDss;
global.ellipsesAfter = ellipsesAfter;
global.encodeBase64 = encodeBase64;
global.submitPostPurchaseForm = submitPostPurchaseForm;
