import { formDataToBody, makeFormData } from "./catalystFormDataBuilder";

// Make a fetch init object with data that will deserialize correctly when posted to catalyst's asp.net-core
// form data model binder
function makeCatalystFetchPostInit(data) {
    return {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": "Fetch"
        },
        credentials: "same-origin",
        method: 'post',
        body: formDataToBody(makeFormData(data))
    }
}

export { makeCatalystFetchPostInit }
