import {observeAnimations} from "../animations";

export const contentRecommendationsSetUpObserver = (myTargetNode, myCallBack) => {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, characterData: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (action) => function(mutationList, observer) {
        // Use traditional 'for loops' for IE 11
        for(const mutation of mutationList) {
            if (mutation.type === 'childList') {
                if (action()) {
                    observeAnimations(mutation.target);
                    observer.disconnect()
                    return;
                }
            }
        }
    };
    const observer = new MutationObserver(callback(myCallBack));
    observer.observe(myTargetNode, config);
};
