// https://stackoverflow.com/questions/5999998/check-if-a-variable-is-of-function-type/7356528#7356528
import {encodeBase64, submitPostPurchaseForm} from "./ecomcommon";

const isFunction = function(valueToCheck) {
    return valueToCheck && {}.toString.call(valueToCheck) === '[object Function]';
}

const formatDss = function($node) {
    const text = $node.text();
    const dss = text.replace(/dss\+/gi, 'dss<sup><i>+</i></sup>').trim();
    $node.html(dss);
};

const ellipsesAfter = function($node, ellipsesAfter) {
    const text = $node.text();
    if(text.trim().length > ellipsesAfter) {
        $node.text(text.trimEnd().substring(0, ellipsesAfter) + "...");
    }
};

export {isFunction, formatDss, ellipsesAfter, encodeBase64, submitPostPurchaseForm}
