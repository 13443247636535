
let page = 1;
let pageSize = 51;
let totalResults = 0;
let selectedFacetOptions = [];

function loadPage(clearResults, includePreviousPages = false) {

    updateBrowserUrl(!clearResults);

    let getResultsUrl = $(".content-hub-page").data("getResultsUrl");
    getResultsUrl = getResultsUrl.substring(0, getResultsUrl.length - 1);

    $.ajax({
        url: getResultsUrl,
        type: "POST",
        data: { page: page, categories: selectedFacetOptions, includePreviousPages: includePreviousPages },
        success: function (response) {
            //console.log(response);
            if (clearResults) {
                $('.js-results-container').html('');
            }
            for (var i = 0; i < response.resultsHtml.length; i++) {
                var html = '<div class="col-12 col-md-6 col-xl-4">' + response.resultsHtml[i] + '</div>';
                $('.js-results-container').append(html);
            }
            totalResults = response.total;
            $('.js-load-more').toggleClass("d-none", page * pageSize >= totalResults);
            updateResultsCountDisplay();
        },
        dataType: 'json'
    });
}

function updateResultsCountDisplay() {
    var resultsCountDisplay = $(".content-hub-page .js-results-count");
    if (totalResults > 0) {
        var highestShown = Math.min(page * pageSize, totalResults)
        resultsCountDisplay.html(resultsCountDisplay.data("format").replace("{0}", highestShown).replace("{1}", totalResults));
    } else {
        resultsCountDisplay.html(resultsCountDisplay.data("noResults"));
    }
}


function updateBrowserUrl(replaceState = false) {
    var newUrl = window.location.href.split('?')[0];

    let params = [];
    if (page > 1) params.push(`p=${page}`);
    if (selectedFacetOptions.length > 0) params.push(`c=${selectedFacetOptions.join(",")}`)

    if (params.length > 0) {
        newUrl += `?${params.join("&")}`;
    }

    //if (replaceState) {
    history.replaceState([], null, newUrl);
    //} else {
    //    history.pushState([], null, newUrl);
    //}
}

export function initContentHubJs() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('p')) page = urlParams.get('p');
    if (urlParams.has('c')) selectedFacetOptions = urlParams.get('c').split(",");

    for (var i = 0; i < selectedFacetOptions.length; i++) {
        $(`.content-hub-page input[data-option-id='${selectedFacetOptions[i]}']`).prop('checked', true);
        addFilter(selectedFacetOptions[i]);
    }
    $('.content-hub-page .filter-by-wrapper').toggleClass('d-none', selectedFacetOptions.length == 0);

    $('.content-hub-page .facet input').change(function () {
        const id = $(this).data('optionId').toString();
        if (this.checked && !selectedFacetOptions.includes(id)) {
            selectedFacetOptions.push(id);
            addFilter(id);
        }
        else {
            if (selectedFacetOptions.includes(id)) {
                selectedFacetOptions.splice(selectedFacetOptions.indexOf(id), 1);
                $(`.content-hub-page .filter-by-wrapper .filter[data-option-id="${id}"]`).remove();
            }
        }
        $('.content-hub-page .filter-by-wrapper').toggleClass('d-none', selectedFacetOptions.length == 0);

        page = 1;
        loadPage(true);
    });

    $('.content-hub-page .js-load-more').click(function () {
        page++;
        loadPage(false);
    });

    // Load initial page results
    //loadPage(true, true);
}

function addFilter(optionId) {
    const checkbox = $(`.content-hub-page input[data-option-id='${optionId}']`);

    var facetTitle = checkbox.closest('.facet').data('facetTitle');

    $('.content-hub-page .filter-by-wrapper').append(
        `<div class="filter" data-option-id=${optionId}>
            <span class="filter-label">${facetTitle}: ${checkbox.next().text()}</span>
            <button class="remove-button" type="button"><i class="fa fa-times" aria-hidden="true"></i></button>
        </div>`);

    $(`.content-hub-page .filter-by-wrapper .filter[data-option-id="${optionId}"]`).click(function () {
        $(`.content-hub-page .facet input[data-option-id="${$(this).data("optionId")}"]`).prop('checked', false);
        if (selectedFacetOptions.includes(optionId)) {
            selectedFacetOptions.splice(selectedFacetOptions.indexOf(optionId), 1);
        }
        $('.content-hub-page .filter-by-wrapper').toggleClass('d-none', selectedFacetOptions.length == 0);
        page = 1;
        loadPage(true);
        $(this).remove();
    });
}
