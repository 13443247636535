/** Create a URLSearchParams from a FormData object. Not suitable for Blob
 * @throws if data is Blob
 * */
const formDataToBody = (formData) => {
    if (formData instanceof FormData) {
        for (var value of formData.values()) {
            if (value instanceof Blob) {
                // to do this we should implement a multipart-form-data content-type
                // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
                // see https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
                throw new Error("cannot yet use Blobs in catalystFormDataBuilder")
            }
        }
    }
    return new URLSearchParams(formData);
};

let addArrayElement = (formData, prefix, datum, i) => {
    addData(formData, `${prefix}[${i}]`, datum)
}

let addArray = (formData, prefix, data) => {
    let i = 0
    data.forEach(datum => addArrayElement(formData, prefix, datum, i++))
}

let addObject = (formData, prefix, data) => {
    var keys = Object.getOwnPropertyNames(data);
    if (prefix !== "") {
        prefix += "."
    }
    for (const key of keys) {
        addData(formData, prefix + key, data[key])
    }
}

let addData = (formData, prefix, data) => {
    if (data === null) return;
    if (Array.isArray(data)) {
        addArray(formData, prefix, data)
    } else if (typeof data === 'object') {
        addObject(formData, prefix, data);
    } else {
        formData.append(prefix, data);
    }
}

/** Build form data that will have keys that can be bound to a model using asp.net core form data model-binder */
const makeFormData = data => {
    const prefix = "";
    const formData = new FormData();
    if (typeof 'data' != 'undefined' && data !== null) {
        addData(formData, prefix, data);
    }
    return formData;
}

export {formDataToBody, makeFormData}
