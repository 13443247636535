
let page = 1;
let pageSize = 9;
let totalResults = 0;
let eventGroup = 0;
let selectedFacetOptions = [];

function loadPage(clearResults, includePreviousPages = false) {

    updateBrowserUrl(!clearResults);

    let getEventsUrl = $(".events-page").data("getEventsUrl");
    getEventsUrl = getEventsUrl.substring(0, getEventsUrl.length - 1);

    $.ajax({
        url: getEventsUrl,
        type: "POST",
        data: { page: page, categories: selectedFacetOptions, includePreviousPages: includePreviousPages, eventGroup: eventGroup },
        success: function (response) {
            //console.log(response);
            if (clearResults) {
                $('.js-results-container').html('');
            }
            for (let i = 0; i < response.resultsHtml.length; i++) {
                const html = '<div class="result">' + response.resultsHtml[i] + '</div>';
                $('.js-results-container').append(html);
            }
            totalResults = response.total;
            if (page * pageSize >= totalResults) {
                $('.js-load-more').hide();
            } else {
                $('.js-load-more').show();
            }

            const hideFilters = totalResults <= pageSize && selectedFacetOptions.length == 0;
            $('.events-page .js-facets-column').toggleClass('d-none', hideFilters);
            $('.events-page .js-results-column').toggleClass('col-lg-9', !hideFilters);

            updateResultsCountDisplay();
        },
        dataType: 'json'
    });
}

function updateResultsCountDisplay() {
    let resultsCountDisplay = $(".events-page .js-results-count");
    if (totalResults > 0) {
        let highestShown = Math.min(page * pageSize, totalResults)
        resultsCountDisplay.html(resultsCountDisplay.data("format").replace("{0}", highestShown).replace("{1}", totalResults));
    } else {
        resultsCountDisplay.html(resultsCountDisplay.data("noResults"));
    }
}


function updateBrowserUrl(replaceState = false) {
    let newUrl = window.location.href.split('?')[0];

    let params = [];
    if (page > 1) params.push(`p=${page}`);
    if (selectedFacetOptions.length > 0) params.push(`c=${selectedFacetOptions.join(",")}`)
    if (eventGroup != 0) params.push(`g=${eventGroup}`);

    if (params.length > 0) {
        newUrl += `?${params.join("&")}`;
    }

    //if (replaceState) {
    history.replaceState([], null, newUrl);
    //} else {
    //    history.pushState([], null, newUrl);
    //}
}

export function initEventsJs() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('p')) page = urlParams.get('p');
    if (urlParams.has('c')) selectedFacetOptions = urlParams.get('c').split(",");
    if (urlParams.has('g')) eventGroup = urlParams.get('g');

    for (let i = 0; i < selectedFacetOptions.length; i++) {
        $(`.events-page input[data-option-id='${selectedFacetOptions[i]}']`).prop('checked', true);
        addFilter(selectedFacetOptions[i]);
    }
    $('.events-page .filter-by-wrapper').toggleClass('d-none', selectedFacetOptions.length == 0);

    $('.events-page .facet input').change(function () {
        const id = $(this).data('optionId').toString();
        if (this.checked && !selectedFacetOptions.includes(id)) {
            selectedFacetOptions.push(id);
            addFilter(id);
        }
        else {
            if (selectedFacetOptions.includes(id)) {
                selectedFacetOptions.splice(selectedFacetOptions.indexOf(id), 1);
                $(`.events-page .filter-by-wrapper .filter[data-option-id="${id}"]`).remove();
            }
        }
        $('.events-page .filter-by-wrapper').toggleClass('d-none', selectedFacetOptions.length == 0);

        page = 1;
        loadPage(true);
    });

    $('.events-page .js-event-group-buttons button').click(function (e) {
        let btnEventGroup = $(e.target).data('eventGroup');
        if (btnEventGroup != eventGroup) {
            eventGroup = btnEventGroup;
            $('.events-page .js-event-group-buttons button').each((i, btn) => {
                $(btn).toggleClass("active", $(btn).data('eventGroup') == eventGroup);
            });

            page = 1;
            $('.events-page input').prop('checked', false);
            $('.events-page .filter-by-wrapper .filter').remove();
            $('.events-page .filter-by-wrapper').toggleClass('d-none', true);
            selectedFacetOptions = [];
            loadPage(true);
        }
    });

    $('.events-page .js-load-more').click(function () {
        page++;
        loadPage(false);
    });

    loadPage(true, true);
}

function addFilter(optionId) {
    const checkbox = $(`.events-page input[data-option-id='${optionId}']`);

    $('.events-page .filter-by-wrapper').append(
        `<div class="filter" data-option-id=${optionId}>
            <span class="filter-label">${checkbox.next().text()}</span>
            <button class="remove-button" type="button"><i class="fa fa-times" aria-hidden="true"></i></button>
        </div>`);

    $(`.events-page .filter-by-wrapper .filter[data-option-id="${optionId}"]`).click(function () {
        $(`.events-page .facet input[data-option-id="${$(this).data("optionId")}"]`).prop('checked', false);
        if (selectedFacetOptions.includes(optionId)) {
            selectedFacetOptions.splice(selectedFacetOptions.indexOf(optionId), 1);
        }
        $('.events-page .filter-by-wrapper').toggleClass('d-none', selectedFacetOptions.length == 0);
        page = 1;
        loadPage(true);
        $(this).remove();
    });
}
