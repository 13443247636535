export const attachPasswordVisibility = (passwordContainer) => {
    let visibilityIcon = passwordContainer.getElementsByClassName("visibility-toggle")[0];
    let password = passwordContainer.getElementsByClassName("pw-field")[0];
    visibilityIcon.addEventListener("mousedown", function () {
        setVisibilityAttribute(password, "text");
    });
    visibilityIcon.addEventListener("mouseleave", function () {
        setVisibilityAttribute(password, "password");
    });
    visibilityIcon.addEventListener("mouseup", function () {
       setVisibilityAttribute(password, "password");
    });
}

export const initializeTooltip = (toolTipIcon, toolTip) => {
    if(!toolTipIcon || !toolTip)
        return;
    toolTipIcon.addEventListener("mouseenter", function () {
        toolTip.style.display = 'inline';
    })

    toolTipIcon.addEventListener("mouseleave", function () {
        toolTip.style.display = 'none';
    })
}

const setVisibilityAttribute = (password, type) => {
    if(!type)
        type = password.getAttribute("type") === "password" ? "text" : "password";

    password.setAttribute("type", type);
}
