//benc starts
var END_OF_INPUT = -1;
var base64Str;
var base64Count;
var base64Chars = new Array(
'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f',
'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
'o', 'p', 'q', 'r', 's', 't', 'u', 'v',
'w', 'x', 'y', 'z', '0', '1', '2', '3',
'4', '5', '6', '7', '8', '9', '+', '/'
);
function setBase64Str(str) {
    base64Str = str;
    base64Count = 0;
}
function readBase64() {
    if (!base64Str) return END_OF_INPUT;
    if (base64Count >= base64Str.length) return END_OF_INPUT;
    var c = base64Str.charCodeAt(base64Count) & 0xff;
    base64Count++;
    return c;
}
export const submitPostPurchaseForm = (accountEmailCulture, dashboardKey, postPurchaseUrl) => {
    let form = document.createElement("form");
    form.action = postPurchaseUrl;
    form.method = "POST";
    let input = document.createElement("input");
    input.type = "hidden";
    input.name = "hdnData";
    input.value = window.encodeBase64(accountEmailCulture + "|" + dashboardKey);
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
}
export const encodeBase64 = (str) => {
    setBase64Str(str);
    var result = '';
    var inBuffer = new Array(3);
    var lineCount = 0;
    var done = false;
    while (!done && (inBuffer[0] = readBase64()) != END_OF_INPUT) {
        inBuffer[1] = readBase64();
        inBuffer[2] = readBase64();
        result += (base64Chars[inBuffer[0] >> 2]);
        if (inBuffer[1] != END_OF_INPUT) {
            result += (base64Chars[((inBuffer[0] << 4) & 0x30) | (inBuffer[1] >> 4)]);
            if (inBuffer[2] != END_OF_INPUT) {
                result += (base64Chars[((inBuffer[1] << 2) & 0x3c) | (inBuffer[2] >> 6)]);
                result += (base64Chars[inBuffer[2] & 0x3F]);
            } else {
                result += (base64Chars[((inBuffer[1] << 2) & 0x3c)]);
                result += ('=');
                done = true;
            }
        } else {
            result += (base64Chars[((inBuffer[0] << 4) & 0x30)]);
            result += ('=');
            result += ('=');
            done = true;
        }
        lineCount += 4;
        if (lineCount >= 76) {
            result += ('\n');
            lineCount = 0;
        }
    }
    return result;
}
function readReverseBase64() {
    var reverseBase64Chars = new Array();
    for (var i = 0; i < base64Chars.length; i++) {
        reverseBase64Chars[base64Chars[i]] = i;
    }
    if (!base64Str) return END_OF_INPUT;
    while (true) {
        if (base64Count >= base64Str.length) return END_OF_INPUT;
        var nextCharacter = base64Str.charAt(base64Count);
        base64Count++;
        if (reverseBase64Chars[nextCharacter]) {
            return reverseBase64Chars[nextCharacter];
        }
        if (nextCharacter == 'A') return 0;
    }
    return END_OF_INPUT;
}
function ntos(n) {
    n = n.toString(16);
    if (n.length == 1) n = "0" + n;
    n = "%" + n;
    return unescape(n);
}
function decodeBase64(str) {
    setBase64Str(str);
    var result = "";
    var inBuffer = new Array(4);
    var done = false;
    while (!done && (inBuffer[0] = readReverseBase64()) != END_OF_INPUT && (inBuffer[1] = readReverseBase64()) != END_OF_INPUT) {
        inBuffer[2] = readReverseBase64();
        inBuffer[3] = readReverseBase64();
        result += ntos((((inBuffer[0] << 2) & 0xff) | inBuffer[1] >> 4));
        if (inBuffer[2] != END_OF_INPUT) {
            result += ntos((((inBuffer[1] << 4) & 0xff) | inBuffer[2] >> 2));
            if (inBuffer[3] != END_OF_INPUT) {
                result += ntos((((inBuffer[2] << 6) & 0xff) | inBuffer[3]));
            } else {
                done = true;
            }
        } else {
            done = true;
        }
    }
    return result;
}
//benc ends
//s4s starts
var ak = "170553596D05160E43030B14061B0000190D19094701161C0D130F0452025041057A0D00"
var ces4 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    ec: function(input) {
        var output = [];
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        input = ces4._u8_ec(input);
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output.push(this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4));
        }
        return output.join("");
    },
    _u8_ec: function(string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = [];
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                utftext.push(String.fromCharCode(c));
            }
            else if ((c > 127) && (c < 2048)) {
                utftext.push(String.fromCharCode((c >> 6) | 192));
                utftext.push(String.fromCharCode((c & 63) | 128));
            }
            else {
                utftext.push(String.fromCharCode((c >> 12) | 224));
                utftext.push(String.fromCharCode(((c >> 6) & 63) | 128));
                utftext.push(String.fromCharCode((c & 63) | 128));
            }
        }
        return utftext.join("");
    }
}
function s4s(txt) {
    return varenc(ak, ces4.ec(txt));
}
function varenc(key, tmptext) {
    var kl = key.length, sl = tmptext.length, str = [], tstr;
    for (var i = 0; i < sl; i++) {
        tstr = (tmptext.charCodeAt(i).toString() ^ key.charCodeAt(((i + 1) % kl)).toString()).toString(16);
        str.push((tstr.length == 1) ? "0" + tstr : tstr)
    }
    return str.join("").toUpperCase()
}
function appendxml(tmpelm, tmplist) {
    var nodestr = ""
    var tmpval = ""
    if ((tmplist.indexOf(tmpelm.name) >= 0 || tmplist == "") && tmpelm.name != "") {
        tmpval = tmpelm.value
        if (tmpelm.type == "select-multiple") {
            var tmpsellistindex = []
            var tmpsellistvalue = []
            while (tmpelm.selectedIndex != -1) {
                tmpsellistvalue.push(tmpelm.options[tmpelm.selectedIndex].value);
                tmpsellistindex.push(tmpelm.selectedIndex)
                tmpelm.options[tmpelm.selectedIndex].selected = false;
            }
            for (var i = 0; i < tmpsellistindex.length; i++) {
                tmpelm.options[tmpsellistindex[i]].selected = true
            }
            tmpval = tmpsellistvalue.join(",")
        }
        if (isNaN(tmpval))
            nodestr = "<" + tmpelm.name + "><![CDATA[" + tmpval + "]]></" + tmpelm.name + ">"
        else
            nodestr = "<" + tmpelm.name + ">" + tmpval + "</" + tmpelm.name + ">"
    }
    return nodestr
}
function writeformxml(srcform, elmlist, varslist) {
    var frmxml = []
    frmxml.push("<root>")
    try {
        frmxml.push("<RequestFrom><![CDATA[" + String(window.location) + "]]></RequestFrom>")
    } catch (E) { frmxml.push("<RequestFrom><![CDATA[" + E.message + "]]></RequestFrom>") }
    var s = varslist.split(",")
    if (varslist.length > 0) {
        for (var i = 0; i < s.length; i++) {
            var nodeName = s[i].split(".")[s[i].split(".").length - 1]
            if (isNaN(eval("AppVars." + s[i])))
                frmxml.push("<" + nodeName + "><![CDATA[" + eval("AppVars." + s[i]) + "]]></" + nodeName + ">")
            else
                frmxml.push("<" + nodeName + ">" + eval("AppVars." + s[i]) + "</" + nodeName + ">")
        }
    }
    if (srcform) {
        s = srcform.getElementsByTagName("SELECT")
        for (var i = 0; i < s.length; i++) {
            frmxml.push(appendxml(s[i], elmlist))
        }
        s = srcform.getElementsByTagName("INPUT")
        for (var i = 0; i < s.length; i++) {
            if (s[i].type == "radio" || s[i].type == "checkbox") {
                if (s[i].checked) {
                    frmxml.push(appendxml(s[i], elmlist))
                }
            }
            else {
                frmxml.push(appendxml(s[i], elmlist))
            }
        }
        var s = srcform.getElementsByTagName("TEXTAREA")
        for (var i = 0; i < s.length; i++) {
            frmxml.push(appendxml(s[i], elmlist))
        }
    }
    frmxml.push("</root>")
    return s4s(frmxml.join(""))
}
function replacepipe(str) {
        str = ((typeof str == "undefined" || str == null) ? "" : str.toString())
        if (str != "") {
            var rText = str.replace(/\|/g, "&#124;");
            return escape(rText);
        }

        return str;
}
//s4s ends
//loadlanguage starts
function writeInnerHtml(x, tagName) {
    l = x.getElementsByTagName(tagName)
    for (var i = 0; i < l.length; i++) {
        if (l[i].getAttribute(attname) != "" && l[i].getAttribute(attname) != null) {
            tmpClabel = getLabel(l[i].getAttribute(attname))
            if (tmpClabel != "") {
                l[i].innerHTML = tmpClabel
            }
        }
        if (l[i].getAttribute(tooltipAttrName) != "" && l[i].getAttribute(tooltipAttrName) != null) {
            tooltipAttrValue = getLabel(l[i].getAttribute(tooltipAttrName))
            if (tooltipAttrValue != "") {
                l[i].alt = tooltipAttrValue;
                l[i].title = tooltipAttrValue;
            }
        }
        if (l[i].getAttribute(datatipAttrName) != "" && l[i].getAttribute(datatipAttrName) != null) {
            tooltipAttrValue = getLabel(l[i].getAttribute(datatipAttrName))
            if (tooltipAttrValue != "") {
                l[i].setAttribute('data-tooltip', tooltipAttrValue);
            }
        }
    }
}
function writeAttribute(x, tagName) {
    l = x.getElementsByTagName(tagName)
    for (var i = 0; i < l.length; i++) {
        if (l[i].getAttribute(tooltipAttrName) != "" && l[i].getAttribute(tooltipAttrName) != null) {
            tooltipAttrValue = getLabel(l[i].getAttribute(tooltipAttrName))
            if (tooltipAttrValue != "") {
                l[i].alt = tooltipAttrValue;
                l[i].title = tooltipAttrValue;
            }
        }
        if (l[i].getAttribute(datatipAttrName) != "" && l[i].getAttribute(datatipAttrName) != null) {
            tooltipAttrValue = getLabel(l[i].getAttribute(datatipAttrName))
            if (tooltipAttrValue != "") {
                l[i].setAttribute('data-tooltip', tooltipAttrValue);
            }
        }
    }
}
var attname = "lang";
var tooltipAttrName = "langtip";
var datatipAttrName = "langdatatip";
function writelabeltiny(x) {
    if (x == null) {
        return;
    }
    writeInnerHtml(x, "span");
    return 1
}
function writelabel(x) {
    if (x == null) {
        return;
    }

    l = x.getElementsByTagName("INPUT")
    for (var i = 0; i < l.length; i++) {
        if (l[i].getAttribute(attname) != "" && l[i].getAttribute(attname) != null) {
            tmpClabel = getLabel(l[i].getAttribute(attname))
            if (tmpClabel != "") {
                l[i].value = tmpClabel;
                if (l[i].getAttribute("type").toLowerCase() == "submit" || l[i].getAttribute("type").toLowerCase() == "checkbox") {
                    l[i].alt = tmpClabel;
                    l[i].title = tmpClabel;
                }

            }
        }
        if (l[i].getAttribute(tooltipAttrName) != "" && l[i].getAttribute(tooltipAttrName) != null) {
            tooltipAttrValue = getLabel(l[i].getAttribute(tooltipAttrName))
            if (tooltipAttrValue != "") {
                l[i].alt = tooltipAttrValue;
                l[i].title = tooltipAttrValue;
            }
        }
    }

    writeInnerHtml(x, "div");
    writeInnerHtml(x, "h1");
    writeInnerHtml(x, "h2");
    writeInnerHtml(x, "h3");
    writeInnerHtml(x, "h4");
    writeInnerHtml(x, "li");
    writeInnerHtml(x, "label");
    writeInnerHtml(x, "title");
    writeInnerHtml(x, "span");
    writeInnerHtml(x, "i");
    writeAttribute(x, "a");

    var ta = x.getElementsByTagName("TEXTAREA")
    for (var i = 0; i < ta.length; i++) {
        var tmpta = ta[i].value
        var tmpdiv = document.createElement("DIV")
        tmpdiv.innerHTML = ta[i].value
        l = tmpdiv.getElementsByTagName("span")
        for (var j = 0; j < l.length; j++) {
            if (l[j].getAttribute(attname) != "" && l[j].getAttribute(attname) != null) {
                tmpClabel = getLabel(l[j].getAttribute(attname))
                if (tmpClabel != "") {
                    tmpta = tmpta.replace("<span " + attname + "=" + l[j].getAttribute(attname) + "></span>", tmpClabel)
                }
            }
        }
        ta[i].value = tmpta
        ta[i].style.visibility = "visible"

    }
    l = x.getElementsByTagName("img")
    for (var i = 0; i < l.length; i++) {
        try {
            if (l[i].getAttribute(attname) != "" && l[i].getAttribute(attname) != null) {
                tmpClabel = getLabel(l[i].getAttribute(attname))
                if (tmpClabel != "") {
                    langkey = l[i].lang
                    if (langkey.substring(0, 3) == "LBL") {
                        l[i].alt = tmpClabel;
                        l[i].title = tmpClabel;
                    }
                    else if (langkey.substring(0, 3) == "PTL") {
                        var sublangkey = langkey.replace("PTL", "");
                        tmpClabel = getMultiLabel("PTL" + sublangkey, "LBL" + sublangkey);
                        l[i].alt = tmpClabel;
                        l[i].title = tmpClabel;
                        tmpClabel = getMultiLabel("PTL" + sublangkey, "PTH" + sublangkey);
                        l[i].setAttribute("src", tmpClabel)
                    }
                    else {
                        l[i].setAttribute("src", tmpClabel)
                    }
                }
            }
        } catch (ex) { }
    }
    l = x.getElementsByTagName("SELECT")
    for (var i = 0; i < l.length; i++) {
        for (var j = 0; j < l[i].length; j++) {
            if (l[i][j].getAttribute(attname) != "" && l[i][j].getAttribute(attname) != null) {
                tmpClabel = getLabel(l[i][j].getAttribute(attname))
                if (tmpClabel != "") {
                    l[i][j].text = tmpClabel
                    l[i][j].setAttribute("title", tmpClabel)
                }
            }
            if (l[i][j].getAttribute(tooltipAttrName) != "" && l[i][j].getAttribute(tooltipAttrName) != null) {
                tooltipAttrValue = getLabel(l[i][j].getAttribute(tooltipAttrName))
                if (tooltipAttrValue != "") {
                    l[i][j].setAttribute("title", tooltipAttrValue)
                }
            }
            else {
                l[i][j].setAttribute("title", l[i][j].text)
            }
        }
    }

    l = x.getElementsByTagName("a");
    var langkey;
    for (var i = 0; i < l.length; i++) {
        try {
            if (l[i].getAttribute(attname) != "" && l[i].getAttribute(attname) != null) {
                tmpClabel = getLabel(l[i].getAttribute(attname))
                if (tmpClabel != "") {
                    langkey = l[i].lang
                    if (langkey.substring(0, 3) == "LBL") {
                        l[i].alt = tmpClabel;
                        l[i].title = tmpClabel;
                        l[i].innerHTML = tmpClabel;
                    }
                    else {
                        l[i].setAttribute("href", tmpClabel)
                    }
                }
            }
        } catch (ex) { }
    }
    return 1
}

function getLabel(att, jobj) {
    var lbl;
    var jsonobj
    if (jobj == undefined) {
        jsonobj = LangJs;
    }
    else {
        jsonobj = jobj;
    }
    try {
        lbl = jsonobj[att]
    }
    catch (w) { }
    return (lbl == null) ? "" : lbl
}
function getMultiLabel(att, childatt, jobj) {
    var lbl;
    var jsonobj
    if (jobj == undefined) {
        jsonobj = LangJs;
    }
    else {
        jsonobj = jobj;
    }
    try {
        lbl = jsonobj[att][childatt]
    }
    catch (w) { }
    return (lbl == null) ? "" : lbl
}
function getSiteConfigLabel(parentAtt, childAtt, jobj) {
    var lbl;
    var jsonobj
    if (jobj == undefined) {
        jsonobj = LangJs;
    }
    else {
        jsonobj = jobj;
    }
    try {
        lbl = jsonobj[parentAtt][childAtt];
    }
    catch (w) { }
    return (lbl == null) ? "" : lbl
}

//loadlanguage ends
//ajax starts
function makeAjaxRequest(url, postdata, callBackHandler) {
    $(document).ready(function () {
        $.ajax({
            url: url,
            data: { data: postdata },
            type: 'POST',
            dataType: 'json',
            crossDomain: true,
            success: function (data) {
                callBackHandler(data);
                return true;
            },
            error: function (data, status) {
                //window.location.href = '/Error.htm';
                return false;
            }
        });

    });
}
//ajax ends
