(function ($) {

    // Waiting so epi.EPiServer.Forms is loaded
    window.addEventListener('DOMContentLoaded', function () {
        if (typeof epi != 'undefined' && epi && epi.EPiServer && epi.EPiServer.Forms && epi.EPiServer.Forms.Extension && epi.EPiServer.Forms.Extension.getCustomElementValue) {
            const originalGetCustomElementValue = epi.EPiServer.Forms.Extension.getCustomElementValue;


            // extend the EpiForm JavaScript API in ViewMode
            $.extend(true, epi.EPiServer.Forms, {
                Validators: {
                    "BBSCatalyst.Web.Code.Forms.Validation.DssGoogleRecaptchaV3Validator": function () {
                        return {};
                    }
                },

                Extension: {

                    // OVERRIDE
                        onBeforeSubmit: function (currentForm) {
                        return this._getRecaptchaToken(currentForm);
                    },

                    // get reCaptcha token.
                    _getRecaptchaToken: function (form) {

                        if(typeof(grecaptcha) === "undefined") {
                            return "";
                        }

                        const $deferred = $.Deferred(),
                            $recaptchaElement = $(form).find(".DssFormRecaptchaV3");

                        // If we don't have recaptcha element -> do nothing.
                        if (!$recaptchaElement.hasClass("DssFormRecaptchaV3")) {
                            $deferred.resolve();
                            return $deferred.promise();
                        }

                        const siteKey = $recaptchaElement.data("f-sitekey");
                        // get reCaptcha token to validate in serverside.

                        try {
                            grecaptcha.execute(siteKey).then(function (token) {
                                $recaptchaElement.attr("g-recaptcha-response", token);
                                $deferred.resolve(token);
                            });
                        } catch (err) {
                            $deferred.resolve();
                            return $deferred.promise();
                        }

                        return $deferred.promise();
                    },

                    // OVERRIDE, process to get value from datetime picker element
                    getCustomElementValue: function ($element) {

                        if ($element.hasClass("DssFormRecaptchaV3")) {
                            return $element.attr("g-recaptcha-response");
                        }

                        // if current element is not our job, let others process
                        return originalGetCustomElementValue.apply(this, [$element]);
                    },
                }
            });
        }
    });

})(jQuery || $);
