$(document).ready(function () {

    // prevent scrolling when mobile menu is open
    $('.navbar-toggler').on('click', function () {
        if (!$('.navbar-collapse').hasClass('show')) {
            $('html').css('overflow', 'hidden');
            $('body').addClass('noscroll');
        } else {
            $('html').css('overflow', '');
            $('body').removeClass('noscroll');
        }
    });

    //hide initial messagae on multi registration form when tab is clicked
    $(".registration-multi .nav-link").on('click', function () {
        $(".registration-multi").append('<style>.registration-multi:after{display: none;}</style>');
    });

    $(function () {
        $('.dropdown').on({
            "click": function (event) {
                if ($(event.target).closest('.dropdown-toggle').length) {
                    $(this).data('closable', true);
                } else {
                    $(this).data('closable', false);
                }
            },
            "hide.bs.dropdown": function (event) {
                let hide = $(this).data('closable');
                $(this).data('closable', true);
                return hide;
            }
        });
    });
    // require jquery for flickity
    var jQueryBridget = require('jquery-bridget');
    var Flickity = require('flickity');

    // make Flickity a jQuery plugin
    Flickity.setJQuery($);
    jQueryBridget('flickity', Flickity, $);
});
