let elementChecker = {
    async checkElement(element)
    {
        while (element === null) {
            await new Promise(resolve => requestAnimationFrame(resolve))
        }
        return element;
    }
}
export const observeAnimations = function (element) {
    const blurInTextBlackClass = "blur-in-text";
    const blurInTextRedClass = "blur-in-text-red";
    const blurInTextWhiteClass = "blur-in-text-white";
    const blurInImgClass = "blur-in-img";
    const buttonGrowClass = "obj-grow"

    const options = {
        root: null,
        threshold: 0.15,
        rootMargin: "-166px"//To compensate for page header
    };

    const observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(entry => {
            const blurElements = entry.target.querySelectorAll('.blur-txt.not-section, .blur-this-img.not-section, .blur-slide, .grow-this-button.not-section');
            const flickityButtons = entry.target.querySelectorAll('.flickity-prev-next-button');
            const flickityButtonsArray = flickityButtons ? Array.from(flickityButtons) : [];
            const blurElementArray = blurElements ? Array.from(blurElements) : [];
            if (entry.isIntersecting) {
                for (let i = 0; i < blurElementArray.length; i++) {
                    let delay = (i * 500) + "ms";
                    let element = $(blurElementArray).filter('.blur-' + i)[0];
                    if (element) {
                        element.style.animationDelay = delay;
                        if (element.classList.contains('blur-txt-black')) {
                            element.classList.add(blurInTextBlackClass);
                        } else if (element.classList.contains('blur-txt-white')) {
                            element.classList.add(blurInTextWhiteClass);
                        } else if (element.classList.contains('blur-this-img')) {
                            element.classList.add(blurInImgClass);
                        } else if (element.classList.contains('grow-this-button')) {
                            element.classList.add(buttonGrowClass);
                        } else if (element.classList.contains('blur-slide')) {
                            let textBlurs = element.querySelectorAll('.blur-txt-black');
                            let whiteTextBlurs = element.querySelectorAll('.blur-txt-white');
                            let redTextBlurs = element.querySelectorAll('.blur-text-red');
                            let imgBlurs = element.querySelectorAll('.blur-this-img');
                            for (let j = 0; j < textBlurs.length; j++) {
                                textBlurs[j].style.animationDelay = delay;
                                textBlurs[j].classList.add(blurInTextBlackClass);
                            }
                            for (let j = 0; j < whiteTextBlurs.length; j++) {
                                whiteTextBlurs[j].style.animationDelay = delay;
                                whiteTextBlurs[j].classList.add(blurInTextWhiteClass);
                            }
                            for (let j = 0; j < redTextBlurs.length; j++) {
                                redTextBlurs[j].style.animationDelay = delay;
                                redTextBlurs[j].classList.add(blurInTextRedClass);
                            }
                            for (let j = 0; j < imgBlurs.length; j++) {
                                imgBlurs[j].style.animationDelay = delay;
                                imgBlurs[j].classList.add(blurInImgClass);
                            }

                        }
                    }
                }
                if (flickityButtonsArray.length > 0 && blurElementArray.length > 0) {
                    let animationDelay = blurElementArray.length > 3 ? '2500ms' : '1500ms';
                    for (let i = 0; i < flickityButtonsArray.length; i++) {
                        flickityButtonsArray[i].style.animationDelay = animationDelay;
                        flickityButtonsArray[i].classList.add(buttonGrowClass);
                    }
                }
            }
        });
    }, options);
    observer.observe(element);
}

$(document).ready(function () {
    const fadeInSections = document.querySelectorAll('.animation-fade-in');

    //Do not observe animations in mobile
    if($(window).width() > 991 && $(document).width() > 991) {
        fadeInSections.forEach(section => {
            observeAnimations(section);
        });
    }

    $(window).on('resize', function(){
        if($(window).width() > 991 && $(document).width() > 991) {
            fadeInSections.forEach(section => {
                observeAnimations(section);
            });
        }
    });
});

